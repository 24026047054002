import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCircleUser,
  faUserCircle,
} from "@fortawesome/free-regular-svg-icons";

interface Props {
  postId: number;
  approvePost: (event: React.MouseEvent) => Promise<void>;
  rejectPost: (event: React.MouseEvent) => Promise<void>;
  addComment: (postId: number, name: string, text: string) => Promise<void>;
  errorMessage: string | undefined;
}

const NewComment: React.FC<Props> = (props: Props) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [contentWidth, setContentWidth] = useState("100%");

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      props.addComment(props.postId, name, text);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setContentWidth("70%");
      } else {
        setContentWidth("100%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: contentWidth }}>
      {props.errorMessage !== undefined ? (
        <Alert variant={"danger"}>{props.errorMessage}</Alert>
      ) : null}
      <Form noValidate onSubmit={onSubmit} className="d-flex align-items-center">
        <FontAwesomeIcon className="p-2" icon={faUserCircle} size="xl" />

        <Form.Control
          required
          as="textarea"
          value={text}
          onChange={onTextChange}
          rows={1}
          style={{ flex: 1, marginRight: "10px", resize: "none" }}
          className="rounded-pill"
        />

        <Button
          variant="success"
          onClick={props.approvePost}
          style={{
            borderRadius: "50%",
            border: "0",
            backgroundColor: "#2C5DE5",
          }}
        >
          <FontAwesomeIcon icon={faPaperPlane} size="sm" />
        </Button>
      </Form>
    </div>
  );
};

export default NewComment;
