import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import useAuth from "../utils/useAuth";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const Account = () => {
  const { user, signOut } = useAuth();

  return (
    <Container
      style={{ backgroundColor: "#F4F6F8", padding: 0, height: "90vh" }}
    >
      <Container className="bg-white">
        <Container>
          <button className="border-0 bg-transparent py-3 d-inline-flex align-items-center">
            <Nav.Link as={Link} to="/posts">
              <span className="text-muted me-2">
                <FontAwesomeIcon icon={faX} size="sm" />
              </span>
              <span className="align-self-center">Account</span>
            </Nav.Link>
          </button>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="text-black fs-4" icon={faUser} />
            <div className="mt-3" style={{ paddingLeft: "20px" }}>
              <h5>Lando Norris</h5>
              <p className="text-muted">peter@kontentino.sk</p>
            </div>
          </div>
        </Container>
      </Container>
      <Container className="d-flex justify-content-center align-items-center mt-3">
        {user !== undefined ? (
          <div style={{ width: "100%" }}>
            <Card className="border-0">
              <Card.Header className="bg-white border-0">
                <p className="mb-0 fs-6 text-muted weight-light">General</p>
              </Card.Header>
              <Card.Body>
                <Link to="/about">
                  <Button
                    variant="light"
                    className="bg-white border-0 w-100 mb-2 text-start"
                  >
                    About
                    <span className="float-end">
                      <FontAwesomeIcon
                        className="text-muted"
                        icon={faChevronRight}
                      />
                    </span>
                  </Button>
                </Link>
                <Link to="/login">
                  <Button
                    variant="light"
                    className="bg-white border-0 w-100 text-start"
                    onClick={signOut}
                  >
                    Log out
                    <span className="float-end">
                      <FontAwesomeIcon
                        className="text-muted"
                        icon={faChevronRight}
                      />
                    </span>
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </div>
        ) : null}
      </Container>
      <Container className="d-flex justify-content-center align-items-center mt-3">
        {user !== undefined ? (
          <div style={{ width: "100%" }}>
            <Card className="border-0">
              <Card.Header className="bg-white border-0">
                <p className="mb-0 fs-6 text-muted weight-light">Help</p>
              </Card.Header>
              <Card.Body>
                <Link to="/help">
                  <Button
                    variant="light"
                    className="bg-white border-0 w-100 text-start"
                  >
                    Report issue
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          </div>
        ) : null}
      </Container>
    </Container>
  );
};

export default Account;
