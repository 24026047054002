let token: string | null = null;

const getToken = (): string | null => {
  return token;
}

const setToken = (newToken: string | null): void => {
  token = newToken;
}

export {
  getToken,
  setToken,
}
