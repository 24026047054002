import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { register } from "../../api/services";

interface Props {
  onSuccess: (user: any) => void;
}

const RegisterForm: React.FC<Props> = ({ onSuccess }: Props) => {
  const [isValidated, setIsValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const [agreement, setAgreement] = useState(false);

  const onAgreementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(event.target.checked);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setIsValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity()) {
      if (password !== confirmPassword) {
        setHasError(true);
        setErrorMessage("Zadané heslá sa nezhodujú!");
        return;
      }
      register(firstName, lastName, email, password)
        .then((response: any) => {
          onSuccess(response.data.user);
        })
        .catch((error) => {
          setHasError(true);
          if (error.code === 2) {
            setErrorMessage("Užívateľ s touto e-mailovou adresou už existuje!");
          } else {
            setErrorMessage("Nepodarilo sa vytvoriť užívateľa!");
          }
          setIsValidated(false);
        });
    }
  };

  return (
    <>
      {hasError ? <Alert variant={"danger"}>{errorMessage}</Alert> : null}
      <Form noValidate validated={isValidated} onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="firstName">
          <Form.Label className="text-muted small">First name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder=""
            value={firstName}
            onChange={onFirstNameChange}
            className="square rounded-1 py-2"
          />
          <Form.Control.Feedback type="invalid">
            This field is mandatory.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="lastName">
          <Form.Label className="text-muted small">Last name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder=""
            value={lastName}
            onChange={onLastNameChange}
            className="square rounded-1 py-2"
          />
          <Form.Control.Feedback type="invalid">
            This field is mandatory.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label className="text-muted small">E-mail address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder=""
            value={email}
            onChange={onEmailChange}
            className="square rounded-1 py-2"
          />
          <Form.Control.Feedback type="invalid">
            This field is mandatory.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="password">
          <Form.Label className="text-muted small">Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder=""
            value={password}
            onChange={onPasswordChange}
            className="square rounded-1 py-2"
          />
          <Form.Control.Feedback type="invalid">
            This field is mandatory.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="confirmPassword">
          <Form.Label className="text-muted small">Confirm password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder=""
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
            className="square rounded-1 py-2"
          />
          <Form.Control.Feedback type="invalid">
            This field is mandatory.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          {["checkbox"].map((type) => (
            <div key={`default-${type}`} className="mb-3">
              <Form.Check
                id={`default-${type}`}
                label={`I agree to terms of service, privacy policy and data policy.`}
                onChange={onAgreementChange}
              />
            </div>
          ))}
        </Form.Group>

        <div className="d-grid gap-2 mt-4 mb-4">
          <Button
            variant="primary"
            type="submit"
            size="lg"
            className="square bg-primary rounded-1 fw-light fs-6 py-2"
            disabled={!agreement}
          >
            Create an account
          </Button>
        </div>
        <p className="text-muted text-center">
          Already have an account? <Link to="/login">Log in here</Link>.
        </p>
      </Form>
    </>
  );
};

export default RegisterForm;
