import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SEO from "../components/SEO";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import OnboardingBackground1 from "../images/onboarding1.svg";
import OnboardingBackground2 from "../images/onboarding2.svg";
import OnboardingBackground3 from "../images/onboarding3.svg";

const Home: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
  };

  return (
    <SEO title="" description="">
      <Container className="vh-100">
        <Row className="h-85">
          <Col className="d-flex flex-column">
            <div style={{ position: "relative", height: "85vh" }}>
              <Carousel
                controls={false}
                indicators={false}
                activeIndex={activeIndex}
                onSelect={() => {}}
                pause={false}
                slide={false}
              >
                <Carousel.Item>
                  <div>
                    <img
                      src={OnboardingBackground1}
                      className="mx-auto d-block mb-5"
                      width={300}
                      alt="Kontentino Onboarding Background 1"
                    />
                    <div className="my-auto">
                      <p className="lead fs-4 text-center">
                        Get your content approval with ease!
                      </p>
                      <p className="lead fs-6 text-center">
                        The approval process for content creators has never been
                        easier. Make sure that the final posts are approved.
                      </p>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={OnboardingBackground3}
                    className="mx-auto d-block mb-5"
                    width={300}
                    alt="Kontentino Onboarding Background 1"
                  />
                  <div className="my-auto">
                    <p className="lead fs-4 text-center">
                      Save time with notifications!
                    </p>
                    <p className="lead fs-6 text-center">
                      Multiple projects and deadlines can be hard to manage.
                      Never forget about any important information.
                    </p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={OnboardingBackground2}
                    className="mx-auto d-block mb-5"
                    width={300}
                    alt="Kontentino Onboarding Background 1"
                  />
                  <div className="my-auto">
                    <p className="lead fs-4 text-center">
                      Collaborate with your team seamlessly!
                    </p>
                    <p className="lead fs-6 text-center">
                      Don't rely on emails, slack, social media messaging. Find
                      all communication on one place.
                    </p>
                  </div>
                </Carousel.Item>
              </Carousel>
              <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
                <div className="d-grid gap-2 mt-auto mb-4">
                  {activeIndex < 2 ? (
                    <Button
                      variant="primary"
                      type="submit"
                      size="lg"
                      className="square bg-primary rounded-1 fw-light fs-6 py-2"
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  ) : (
                    <Link to="/login" className="text-decoration-none">
                      <Button
                        href="/login"
                        variant="primary"
                        type="submit"
                        size="lg"
                        className="square bg-primary rounded-1 fw-light fs-6 py-2 w-100"
                      >
                        Continue
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Home;
