import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  return (
    <Container>
      <div>
        <button className="border-0 bg-transparent py-3 d-inline-flex align-items-center">
          <Link to="/account" style={{ textDecoration: "none" }}>
            <span className="text-muted me-2">
              <FontAwesomeIcon icon={faAngleLeft} size="sm" />
            </span>
            <span className="text-black align-self-center">Account</span>
          </Link>
        </button>
      </div>
      <div style={{ backgroundColor: "#FFFFFF", padding: "20px" }}>
        <section style={{ marginBottom: "20px" }}>
          <h3>About Us</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed eu
            aliquam mauris. Integer eget ante eu nisi hendrerit blandit.
            Phasellus lacinia nunc nec justo porttitor convallis. Sed nec est at
            quam fermentum gravida eu at ante. In in metus quis purus eleifend
            venenatis at in lectus.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h3>Our Mission</h3>
          <p>
            Vivamus quis velit ligula. Etiam luctus, eros vel posuere ultrices,
            purus felis facilisis tortor, vel interdum orci augue at metus.
            Suspendisse id interdum elit. Proin luctus velit quis interdum
            iaculis. Nullam id augue nec purus sodales efficitur.
          </p>
        </section>

        <section>
          <h3>Our Vision</h3>
          <p>
            Duis rhoncus, massa id finibus cursus, nunc felis suscipit sapien,
            non eleifend orci augue sit amet turpis. Nam malesuada vitae quam
            eget facilisis. Suspendisse eu laoreet neque, nec ullamcorper velit.
          </p>
        </section>
      </div>
    </Container>
  );
};

export default About;
