import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPost, updatePost, createComment } from "../../api/services";
import Panel from "./detail/Panel";
import Container from "react-bootstrap/Container";
import SEO from "../../components/SEO";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faEye, faComment, faEdit } from "@fortawesome/free-regular-svg-icons";

const Comments: React.FC = () => {
  const params = useParams();
  const [post, setPost] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    async function loadPosts() {
      const response = await getPost(parseInt(params.id as string));
      setPost(response.data.post);
    }

    loadPosts();
  }, []);

  const addComment = (
    postId: number,
    name: string,
    text: string
  ): Promise<void> => {
    return createComment(postId, name, text)
      .then((response) => {})
      .catch((error) => {
        setErrorMessage("Failed to add new comment!");
      });
  };

  const approvePost = (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return updatePost({
      id: parseInt(params.id as string),
      status: 1,
    }).then((response) => {
      setPost(response.data.post);
    });
  };

  const rejectPost = (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return updatePost({
      id: parseInt(params.id as string),
      status: 2,
    }).then((response) => {
      setPost(response.data.post);
    });
  };

  const [contentWidth, setContentWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setContentWidth("70%");
      } else {
        setContentWidth("100%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SEO title="Post" description="">
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", width: contentWidth, margin: "0 auto" }}
      >
        <Container fluid style={{ marginTop: 10 }}>
          <div>
            <button className="border-0 bg-transparent py-3 d-inline-flex align-items-center">
              <Link to="/posts" style={{ textDecoration: "none" }}>
                <span className="text-muted me-2">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </span>
                <span className="align-self-center text-black">Feed</span>
              </Link>
            </button>
          </div>
          <div style={{ borderRadius: 10, marginBottom:"15vh" }}>
            {post !== null ? (
              <Panel
                post={post}
                errorMessage={errorMessage}
                addComment={addComment}
                approvePost={approvePost}
                rejectPost={rejectPost}
              />
            ) : null}
          </div>
          <div
            className="d-flex flex-direction-column justify-content-evenly w-100 bg-white py-4"
            style={{ position: "fixed", bottom: 0, left: 0, zIndex: 9999 }}
          >
            <Link style={{color: "black"}} to={`/posts/${params.id}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link to={`/posts/${params.id}/comments`}>
              <FontAwesomeIcon icon={faComment} />
            </Link>
            <Link style={{color: "black"}} to={`/posts/${params.id}/edit`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </div>
        </Container>
      </div>
    </SEO>
  );
};

export default Comments;
