import { post, put, get, postMultipart } from './client';

export async function register(firstName: string, lastName: string, email: string, password: string): Promise<any> {
  return post(`${process.env.REACT_APP_API_URL}/user/register`, { firstName, lastName, email, password });
}

interface LoginResponse {
  error: unknown;
  data: {
    user: {
      id: number;
      email: string;
      expiresAt: string;
      token: string;
    },
  };
}

export async function login(email: string, password: string): Promise<LoginResponse> {
  return post(`${process.env.REACT_APP_API_URL}/user/login`, { email, password }, { credentials: 'include' });
}

export async function logout(): Promise<RefreshTokenResponse> {
  return get(`${process.env.REACT_APP_API_URL}/user/logout`, { credentials: 'include' });
}

interface RefreshTokenResponse {
  error: unknown;
  data: {
    id: number;
    expiresAt: string;
    token: string;
  };
}

export async function refreshToken(): Promise<RefreshTokenResponse> {
  return get(`${process.env.REACT_APP_API_URL}/user/refresh-token`, { credentials: 'include' });
}

interface ValidateResponse {
  error: unknown;
  data: unknown;
}

export async function validate(id: string, token: string): Promise<ValidateResponse> {
  return get(`${process.env.REACT_APP_API_URL}/user/validate?id=${id}&token=${token}`);
}

interface CreatePostResponse {
  error: unknown;
  data: {
    post: Post;
  };
}

export async function createPost(text: string, media: { id: number }[]): Promise<CreatePostResponse> {
  return post(`${process.env.REACT_APP_API_URL}/post/`, { text, media });
}

interface UpdatePostParams {
  id: number;
  name?: string;
  text?: string;
  status?: number;
}

interface UpdatePostResponse {
  error: unknown;
  data: {
    post: Post;
  };
}

export async function updatePost(params: UpdatePostParams): Promise<UpdatePostResponse> {
  const { id, ...data } = params;
  return put(`${process.env.REACT_APP_API_URL}/post/${id}`, data);
}

interface GetPostsParams {
  status?: number;
}

interface GetPostsResponse {
  error: unknown;
  data: {
    posts: Post[],
    pageInfo: {
      count: number;
    }
  };
}

export async function getPosts(params?: GetPostsParams): Promise<GetPostsResponse> {
  const searchParams = new URLSearchParams();

  if (params !== undefined && params.status !== undefined) {
    searchParams.set('status', String(params.status));
  }

  return get(`${process.env.REACT_APP_API_URL}/post/?${searchParams.toString()}`);
}

interface GetPostResponse {
  error: unknown;
  data: {
    post: Post;
  };
}

export async function getPost(id: number): Promise<GetPostResponse> {
  return get(`${process.env.REACT_APP_API_URL}/post/${id}`);
}

interface CreateCommentResponse {
  error: unknown;
  data: {
    post: Comment;
  };
}

export async function createComment(postId: number, name: string, text: string): Promise<CreateCommentResponse> {
  return post(`${process.env.REACT_APP_API_URL}/post/${postId}/comment/`, { name, text });
}

export async function uploadPhoto(file: File): Promise<Media> {
  const formData = new FormData();

  formData.append('image', file);

  return postMultipart(`${process.env.REACT_APP_UPLOAD_URL}/upload`, formData)
    .then(res => {
      return res.data.media;
    });
}

export async function getCommentsForPost(postId: number) {
  try {
    const response = await fetch(`/api/comments/${postId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch comments");
    }
    const data = await response.json();
    return data.comments;
  } catch (error) {
    throw new Error("Failed to fetch comments");
  }
}
