import React, { useState, useEffect } from "react";
import SEO from "../../components/SEO";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "react-bootstrap/Container";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import {
  faCalendar,
  faFlag,
  faClock,
  faEye,
  faComment,
  faEdit,
} from "@fortawesome/free-regular-svg-icons";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

const Edit: React.FC = () => {
  const params = useParams();
  const [contentWidth, setContentWidth] = useState("100%");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setContentWidth("70%");
      } else {
        setContentWidth("100%");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <SEO title="Post" description="">
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh", width: contentWidth, margin: "0 auto" }}
      >
        <Container fluid style={{ marginTop: 10 }}>
          <div>
            <button className="border-0 bg-transparent py-3 d-inline-flex align-items-center">
              <Link to="/posts" style={{ textDecoration: "none" }}>
                <span className="text-muted me-2">
                  <FontAwesomeIcon icon={faAngleLeft} />
                </span>
                <span className="align-self-center text-black">Feed</span>
              </Link>
            </button>
          </div>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FontAwesomeIcon className="fs-5" icon={faFlag} />
                &nbsp; Post content
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="text">
                  <Form.Label>Post text</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      border: "1px solid #ccc",
                      borderColor: "grey",
                    }}
                  />
                  {/* Add Form.Control.Feedback for validation if needed */}
                </Form.Group>
                <Form.Group className="mb-3" controlId="media">
                  <Form.Label>Media</Form.Label>
                  {/* <Upload /> */}
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Post Planning */}
          <Accordion className="mt-3" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FontAwesomeIcon className="fs-5" icon={faCalendar} />
                &nbsp; Post planning
              </Accordion.Header>
              <Accordion.Body>
                {/* Date input */}
                <Form.Group className="mb-3" controlId="date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      borderColor: "grey",
                    }}
                  />
                </Form.Group>
                {/* Time input */}
                <Form.Group className="mb-3" controlId="time">
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    style={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      borderColor: "grey",
                    }}
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/* Notes */}
          <Accordion className="mt-3" defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <FontAwesomeIcon className="fs-5" icon={faClock} />
                &nbsp; Notes
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group className="mb-3" controlId="notes">
                  <Form.Control
                    as="textarea"
                    placeholder="Add notes"
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      border: "1px solid #ccc",
                      borderColor: "grey",
                    }}
                  />
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="d-flex flex-column justify-content-between align-items-center w-100 py-4 mb-5">
            <Button
              className="mx-3 my-1"
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#2C5DE5",
                color: "#FFFFFF",
                borderRadius: "5px",
                border: "none",
              }}
            >
              Submit
            </Button>
            <Button
              className="mx-3 my-1"
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                color: "#000000",
                backgroundColor: "#DFE1E6",
                borderRadius: "5px",
                border: "none",
              }}
            >
              Cancel
            </Button>
          </div>

          <div
            className="d-flex flex-direction-column justify-content-evenly w-100 bg-white py-4"
            style={{ position: "fixed", bottom: 0, left: 0, zIndex: 9999 }}
          >
            <Link style={{color: "black"}} to={`/posts/${params.id}`}>
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link style={{color: "black"}} to={`/posts/${params.id}/comments`}>
              <FontAwesomeIcon icon={faComment} />
            </Link>
            <Link to={`/posts/${params.id}/edit`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </div>
        </Container>
      </div>
    </SEO>
  );
};

export default Edit;
