import React from "react";
import { Link } from "react-router-dom";

const ThankYou: React.FC = () => (
  <>
    <h1>Thank you!</h1>
    <p></p>
    <p>
      An email has been sent to you with a link to verify your email address.
    </p>
    <p>
      After successful verification, you can continue by logging in{" "}
      <Link to="/login">here</Link>.
    </p>
  </>
);

export default ThankYou;
