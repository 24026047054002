import React from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const Help = () => {
  return (
    <Container>
      <div className="d-flex flex-column flex-lg-row">
        <div className="mb-3 mb-lg-0">
          <Link
            to="/account"
            className="btn btn-link"
            style={{ textDecoration: "none" }}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="sm"
              className="me-2"
              style={{ color: "black" }}
            />
            <span className="text-black align-self-center">Account</span>
          </Link>
        </div>
        <div
          className="flex-grow-1 mx-lg-3 mt-5"
          style={{
            backgroundColor: "#F4F6F8",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <h2 className="mb-4">Help</h2>

          <form>
            <div className="mb-3">
              <label className="text-muted" htmlFor="subject">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label className="text-muted" htmlFor="subject">
                Type
              </label>
              <Form.Select className="form-select">
                <option value="1">Choose the type</option>
                <option value="2">Bug</option>
                <option value="3">Report</option>
              </Form.Select>
            </div>

            <div className="mb-3">
              <label className="text-muted" htmlFor="problem">
                Problem
              </label>
              <textarea
                id="problem"
                name="problem"
                rows={4}
                className="form-control"
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Help;
