import React from 'react';
import { Routes, Route } from 'react-router-dom';
import getLazyComponent from '../utils/getLazyComponent';

const Validation = getLazyComponent(() => import('./user/Validation'));

const User: React.FC = () => {
  return (
    <Routes>
      <Route path="/validation" element={<Validation />} />
    </Routes>
  );
}

export default User;
