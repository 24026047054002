import React from 'react';
import { Routes, Route } from 'react-router-dom';
import getLazyComponent from '../utils/getLazyComponent';

const List = getLazyComponent(() => import('./posts/List'));
const Detail = getLazyComponent(() => import('./posts/Detail'));
const New = getLazyComponent(() => import('./posts/New'));

const Posts: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/:id" element={<Detail />} />
      <Route path="/new" element={<New />} />
    </Routes>
  );
}

export default Posts;
