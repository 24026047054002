import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import useAuth from '../utils/useAuth';
import KontentinoLogo from '../images/kontentinoLogo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-regular-svg-icons'

const Menu = () => {
  const { user } = useAuth();

  return (
    <Navbar className="block-example border-bottom border-light py-3 menubg" bg="0" variant="light" expand="lg">
      <Container fluid>
        <Navbar.Brand className="me-auto" as={Link} to="/posts">
          <img
            src={KontentinoLogo}
            width="120"
            height="30"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        {
         user !== undefined ? (
          <>
            <Nav.Link as={Link} to="/posts"><FontAwesomeIcon className="text-muted px-3" icon={faMagnifyingGlass} /></Nav.Link>
            <Nav.Link as={Link} to="/account"><FontAwesomeIcon className="text-muted px-3" icon={faUser} /></Nav.Link>
          </>
        ) : null
        }
      </Container>
    </Navbar>
  );
}

export default React.memo(Menu);
