import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import NewComment from "./NewComment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";

interface Props {
  post: Post;
  errorMessage: string | undefined;
  approvePost: (event: React.MouseEvent) => Promise<void>;
  rejectPost: (event: React.MouseEvent) => Promise<void>;
  addComment: (postId: number, name: string, text: string) => Promise<void>;
}

const Detail: React.FC<Props> = (props: Props) => (
  <div className="container-fluid">
    <>
      {props.post.comments.length === 0 ? (
        <Alert variant="warning">
          No one has commented this yet! You can share this post with your
          clients.
        </Alert>
      ) : null}
      {props.post.comments.map((comment: any) => {
        if (comment.userId === null) {
          return (
            <Row className="mt-3" key={comment.id}>
              <Col lg={10} sm={11} xs={11}>
                <Card>
                  <Card.Body>
                    <Card.Title>{comment.name}</Card.Title>
                    <Card.Text>{comment.text}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        }

        return (
          <Row className="mt-1" key={comment.id}>
            <Col
              lg={{ span: 12, offset: 0 }}
              sm={{ span: 11, offset: 0 }}
              xs={{ span: 11, offset: 0 }}
            >
              <div className="d-flex mb-2" style={{alignItems:"end"}}>
                <FontAwesomeIcon
                  className="p-2"
                  icon={faCircleUser}
                  size="xl"
                />
                <div>
                  <div
                    className="d-flex text-muted "
                    style={{ marginLeft: "15px" }}
                  >
                    <small>{comment.name} • 23.3.2024, 13:22</small>
                  </div>
                  <div
                    className="p-3"
                    style={{
                      borderRadius: "20px",
                      marginLeft: "10px",
                      backgroundColor: "#dbdbdb",
                      display: "inline-block",
                      maxWidth: "80%",
                      whiteSpace: "normal",
                    }}
                  >
                    {comment.text}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col>
          <div
            style={{
              position: "fixed",
              bottom: 80,
              width: "90%",
            }}
          >
            <NewComment
              postId={props.post.id}
              errorMessage={props.errorMessage}
              approvePost={props.approvePost}
              rejectPost={props.rejectPost}
              addComment={props.addComment}
            />
          </div>
        </Col>
      </Row>
    </>
  </div>
);

export default Detail;
