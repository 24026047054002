import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Menu from "./components/Menu";
// import Footer from './components/Footer';
import ErrorBoundary from "./components/ErrorBoundary";
import Home from "./pages/Onboarding";
import Account from "./pages/Account";
import Help from "./pages/Help";
import About from "./pages/About";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Posts from "./pages/Posts";
import User from "./pages/User";
import Comments from "./pages/posts/Comments";
import Edit from "./pages/posts/Edit";
import { AuthProvider, RequireAuth } from "./utils/useAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <>
          <Menu />
          <main
            role="main"
            className="flex-shrink-0"
            style={{ marginTop: 0, marginBottom: 25 }}
          >
            <ErrorBoundary>
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/account" element={<Account />} />
                <Route path="/help" element={<Help />} />
                <Route path="/about" element={<About />} />
                <Route path="/user/*" element={<User />} />
                <Route path="/" element={<Home />} />
                <Route
                  path="/posts/*"
                  element={
                    <RequireAuth>
                      <Posts />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/posts/:id/comments"
                  element={
                    <RequireAuth>
                      <Comments />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/posts/:id/edit"
                  element={
                    <RequireAuth>
                      <Edit />
                    </RequireAuth>
                  }
                />
              </Routes>
            </ErrorBoundary>
          </main>

          <Routes>
            {/* <Route path="/" element={<Footer />} /> */}
            <Route path="*" element={null} />
          </Routes>
        </>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
