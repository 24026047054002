import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Link, useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import useAuth from '../utils/useAuth';

const Login: React.FC = () => {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [ email, setEmail ] = useState('');
  const [ password, setPassword  ] = useState('');
  const [ error, setError  ] = useState<string | null>(null);

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
      signIn(email, password)
        .then(() => {
          navigate('/posts');
        })
        .catch(() => {
          setError('Incorrect email address or password.');
        });
    }
  };

  return (
    <SEO title="Log In" description="">
      <Container className="main" style={{ marginTop: 50 }}>
        <Row>
          <Col md={{ span: 4, offset: 4 }}>
            {
              error ? (
                <Alert variant={'danger'}>
                  {error}
                </Alert>
              ) : null
            }
            <Form noValidate onSubmit={onSubmit}>
              <h5 className="mb-4 fs-4">Login</h5>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label className="text-muted small">Email</Form.Label>
                <Form.Control required type="email" placeholder="" value={email} onChange={onEmailChange} className="square rounded-1 py-2" />
                <Form.Control.Feedback type="invalid">This field is mandatory.</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label className="text-muted small">Password</Form.Label>
                <Form.Control required type="password" placeholder="" value={password} onChange={onPasswordChange} className="square rounded-1 py-2" />
                <Form.Control.Feedback type="invalid">This field is mandatory.</Form.Control.Feedback>
              </Form.Group>

              <div className="d-grid gap-2 mt-4 mb-4">
                  <Button variant="primary" type="submit" size="lg" className="square bg-primary rounded-1 fw-light fs-6 py-2">Log in</Button>
              </div>
              <p className="text-muted text-center">Don't have an account? <Link to="/register">Register here</Link>.</p>

            </Form>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Login;
